import React, { useState, useRef, useEffect } from "react";
import { Collapse, List, Tooltip, Button, Input, Card, Spin, Flex } from "antd";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
const BPMLevel2 = ({ levelIndex, ...props }) => {
  const [hovered, setHovered] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleDragStart = (e, item) => {
    //console.log("testing dragstart item id", item.id);
    props.setDragStart(item.id);
    props.setCurrentLevel(item.level)
    props.setDraggingItem(item.displayname);
    e.dataTransfer.setData("text/plain", item.displayname); // Set the dragged item
    e.dataTransfer.effectAllowed = "move";

    // Create a custom ghost image for better visibility
    const ghostImage = document.createElement("div");
    ghostImage.innerText = item.displayname;
    ghostImage.style.position = "absolute";
    ghostImage.style.backgroundColor = "rgba(0, 123, 255, 0.7)";
    ghostImage.style.color = "white";
    ghostImage.style.width = "200px";
    ghostImage.style.padding = "10px";
    ghostImage.style.borderRadius = "5px";
    ghostImage.style.pointerEvents = "none"; // Ensure it doesn't interfere with the drag
    document.body.appendChild(ghostImage);
    e.dataTransfer.setDragImage(ghostImage, 0, 0);

    // Remove the ghost image after the drag ends
    e.dataTransfer.onDragEnd = () => {
      document.body.removeChild(ghostImage);
    };
  };

  const handleDragOver = (data) => {
    //data.level:"L1"
    console.log('testing data..............>>>',data);
    
    props.setDragEnd(data.id);
    props.setDragLevel(data.level);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const onChange = (key) => {
    props.setAcitveKey(key);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    props?.setItemDelete(props.data, { type: "delete" });
  };

  const NewItem = ({ ...props }) => {
    const [inputItem, setInputItem] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
      inputRef.current.focus();
    }, []);

    const handleKeyPress = async (e) => {
      if (e.key === "Enter") {
        const obj = {
          referenceid: 413,
          displayname: inputItem,
          attributes: [],
          level: `L${levelIndex}`,
          parentid: props.parentid,
          sourceid: 1,
        };

        await props.create_Duplicate(obj);
        props.setBcm2Index(null);
      }
    };

    const baseStyle = {
      justifyContent: "center",
      margin: "10px",
    };

    return (
      <>
        <Collapse
          style={{ backgroundColor: "#fff" }}
          onMouseEnter={(event) => event.stopPropagation()}
          onMouseLeave={(event) => event.stopPropagation()}
          expandIconPosition={"start"}
          items={[
            {
              key: props.key,
              label: (
                <>
                  <Tooltip
                    title={"Enter Display Name"}
                    //onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }}
                  >
                    <Input
                      onPressEnter={handleKeyPress}
                      style={{ width: "100%" }}
                      defaultValue="Enter Display Name"
                      value={inputItem}
                      onChange={(e) => setInputItem(e.target.value)}
                      ref={inputRef}
                    />
                  </Tooltip>
                </>
              ),
              extra: (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="btn btn-primary dropdown-toggle p-0"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <i
                        className="fa fa-ellipsis-v edit"
                        style={{ color: "black" }}
                      />
                    </button>
                  </div>
                </>
              ),
            },
          ]}
          className="collapse-level-2 m-1"          
        />
      </>
    );
  };

  return (
    <>
      <Collapse
        activeKey={[...props?.defaultAtiveKey, ...props.activeKey]}
        style={{
          backgroundColor:
            props.data.parentid === 0 ? props.backgroundColor : "#fff",
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onChange={onChange}
        //onClick={() => { props?.setItem(props.data); props?.setModalData({ level: (levelIndex + 1), ParentID: props?.data?.id }); }}
        expandIconPosition={"start"}
        expandIcon={({ isActive }) => (
          <DownOutlined
            style={{
              // fontSize: '16px',
              color: isActive ? props.backgroundColor : "black",  // Adjust color as needed
              transition: 'color 0.3s',
            }}
            rotate={isActive ? 180 : 0}
          />
        )}
        
        items={[
          {
            key: props.data.id,
            label: (
              <>
                <div
                      onMouseEnter={() => setHovered(true)}
                      onMouseLeave={() => setHovered(false)}
                      className="d-flex"
                      draggable
                      onDragStart={(e) => handleDragStart(e, props.data)}
                      onDragOver={(e) => {
                        if (props.dragend !== props.data.id) {
                          handleDragOver(props.data);
                        }
                      }}
                      onDragEnd={props.createNewArrey}
                    >
                      <Tooltip
                        title={props?.data.displayname}
                        onClick={() => {
                          navigate(props.basePath + "/" + props?.data?.id, {
                            state: {
                              from: props.basePath,
                              defaultActiveKey: 2,
                            },
                          });
                        }}
                      >
                      <span style={{color:`${props?.data?.parentid === 0 && "#fff"}`}}>{`${props?.data.displayname}`}</span>
                      </Tooltip>
                    </div>
              </>
            ),
            children: (
              <List
                className="abc"
                header={false}
                style={{
                  backgroundColor:
                    props.data.parentid === 0 ? props.backgroundColor : "#fff",
                }}
                size={`small`}
                footer={
                  <div>
                    {props.data.id === props.bcm2Index && (
                      <NewItem
                        activeKey={props.activeKey}
                        setAcitveKey={props.setAcitveKey}
                        parentid={props?.data?.id}
                        create_Duplicate={props.create_Duplicate}
                        setBcm2Index={props.setBcm2Index}
                      />
                    )}

                    <Tooltip
                      title={`Level ` + (levelIndex)}
                      placement={`rightBottom`}
                    >
                      <Button
                        shape="circle"
                        className="btn-level-2"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          props.setBcm2Index(props.data.id);
                        }}
                      ></Button>
                    </Tooltip>
                  </div>
                }
                bordered
                dataSource={[...props.data.child]}
                renderItem={(item, index) => (
                  <>
                    <BPMLevel2
                      {...props}
                      index={index}
                      // key={props?.data?.id}
                      data={item}
                      underLevel={props?.data.displayname}
                      levelIndex={levelIndex + 1}
                      activeKey={props.activeKey}
                      setAcitveKey={props.setAcitveKey}
                      bcm2Index={props.bcm2Index}
                      setBcm2Index={props.setBcm2Index}
                    />
                  </>
                )}
              />
            ),
            extra: (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn btn-primary dropdown-toggle p-0"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdown(!dropdown);
                    }}
                  >
                    <i
                      className="fa fa-ellipsis-v edit"
                      style={{ color: props.data.parentid === 0 ? "#fff" : "black", }}
                    />
                  </button>
                  {dropdown && (
                    <ul
                      ref={dropdownRef}
                      style={{
                        padding: "0px 3px",
                        display: "flex",
                        flexDirection: "row-reverse",
                        gap: 5,
                        position: "fixed",
                        marginLeft: 70,
                        backgroundColor: "#ffffff",
                        borderRadius: 7,
                        height: 32,
                        borderColor: "#ccc",
                        borderWidth: 1.5,
                        zIndex:100
                      }}
                    >
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button onClick={handleDelete} className="btn-icon">
                          <i
                            className="fa fa-trash-o delete"
                            style={{ fontSize: "large" }}
                          />
                        </Button>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          onClick={() => {
                            navigate(props.basePath + "/" + props?.data?.id, {
                              state: {
                                from: props.basePath,
                                defaultActiveKey: 2,
                              },
                            });
                          }}
                          className="btn-icon"
                        >
                          <i
                            className="fa fa-pencil edit"
                            style={{ fontSize: "large" }}
                          />
                        </Button>
                      </li>
                    </ul>
                  )}
                </div>
              </>
            ),
          },
        ]}
        className="collapse-level-2 m-1"
      />
    </>
  );
};

BPMLevel2.defaultProps = {
  levelIndex: 2,
};

export default BPMLevel2;
