import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Tooltip, Button, Carousel, Flex, Modal, message } from "antd";
import {
  UnorderedListOutlined,
  PlusOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import BCMSamples from "./BCMSamples";
// import BCMLevel1 from './BCMLevel1';
import BPMLevel2 from "../businessProcess/grid/BPMLevel2";
import { useNavigate } from "react-router";
import ActionsBtn from "../applicationlist/ActionsBtn";
import { PageTitleHeading } from "../../utils/Common";
import { useParams } from "react-router-dom";
import gridSvg from "./../../assets/Two columns layout.svg";
import LoadingBar from "react-top-loading-bar";
import businessCapabilityServices from "../../services/services/BusinessCapabilityService";
import { UpdateChangeLog } from "../../utils/ChangeLogs";
import { useMenu } from "../../Context/MenuProvider";
import BusinessProcessHeader from "../businessProcess/BusinessProcessHeader";

const baseStyleRow = {
  overflowX: "auto",
  minHeight: window?.innerHeight - 250,
  maxWidth: window?.innerWidth - 250,
};

const BusinessCapabilityGrid = ({ basePath, ...props }) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [showFilter, setshowFilter] = useState(false);
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState("displayName");
  const [orderList, setorderList] = useState("label,ASC");
  const [lists, setLists] = useState([]);
  const [tempList, setTempList] = useState(lists);
  const [filter_By, setfilterBy] = useState("");
  const [filter_ByText, setfilter_ByText] = useState("");
  const [filter_levels, setfilter_levels] = useState("");
  const [filterOrder, setfilterOrder] = useState("label,ASC");
  const [filterChanges, setFilterChanges] = useState(false);
  const [field_OldData, setfield_OldData] = useState({
    displayName: "",
    description: "",
  });
  const { moduleName: moduleNamePath } = useParams();
  const { stateMenu, dispatchMenu } = useMenu();
  const [filterOptions, setFilterOptions] = useState({});
  const [bcm2Index, setBcm2Index] = useState(null);
  const [activeKey, setAcitveKey] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);
  const [defaultAtiveKey, setDefaultAcitveKey] = useState([]);
  const [dragStart, setDragStart] = useState("");
  const [dragend, setDragEnd] = useState("");  
  const [dragLevel, setDragLevel] = useState("");
  const [currentLevel, setCurrentLevel] = useState("");
  const [completeEnd, setCompleteEnd] = useState(false);

  useEffect(() => {
    setTempList(lists);
  }, [lists]);

  const createNewArrey = async () => {
    setLoading(true);
    // Function to calculate the range difference
    function rangeDifference(range1, range2) {
      const num1 = parseInt(range1.slice(1), 10); // Extract numeric part after "L" in range1
      const num2 = parseInt(range2.slice(1), 10); // Extract numeric part after "L" in range2
      return num2 - num1; // Calculate and return the difference
    }
  
    // Function to find an object by ID within a nested structure
    function findObjectById(id, dataArray) {
      for (const item of dataArray) {
        if (item.id === id) return item; // Return the found object
        if (item.child && item.child.length > 0) {
          const found = findObjectById(id, item.child); // Search recursively in children
          if (found) return found;
        }
      }
      return null; // Return null if not found
    }
  
    // Function to update levels recursively
    function updateLevels(data, change) {
      const results = []; // Array to hold updated objects
  
      const updateLevelRecursively = (item) => {
        let currentLevelNum = parseInt(item.level.replace("L", ""), 10);
        currentLevelNum += change;
        currentLevelNum = Math.max(currentLevelNum, 0); // Ensure level is non-negative
        const newLevel = `L${currentLevelNum}`;
  
        results.push({
          id: item.id,
          level: newLevel,
        });
  
        // Process child items recursively
        if (item.child && item.child.length > 0) {
          item.child.forEach(updateLevelRecursively);
        }
      };
  
      updateLevelRecursively(data);
      return results; // Return the updated levels
    }
    
    try {
      if (dragStart !== dragend && typeof dragend === "number") {
        const prefix = dragLevel.slice(0, 1); // Extract "L" prefix
        const number = parseInt(dragLevel.slice(1), 10) + 1; // Increment level
        const result = `${prefix}${number}`;
  
        const diff = rangeDifference(currentLevel, result);
        const resultObj = findObjectById(dragStart, lists);
        if (resultObj) {
          const updatedLevelObj = updateLevels(resultObj, diff);
          await businessCapabilityServices.drag_update({
            id: dragStart,
            parentId: dragend,
            level: currentLevel === result ? [] : updatedLevelObj,
          });
          await getBusinessCapabilityList();

        } else {
          console.error("Error: Object not found in the list.");
        }

        
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setDragStart("");
      setDragEnd("");
    }
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
        onDragEnter={onClick}
      >
        <RightCircleOutlined />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
        onDragEnter={onClick}
      >
        <LeftCircleOutlined />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // swipeToSlide: true,
    // draggable: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    getBusinessCapabilityList(
      filter_By ? filter_By : "",
      filter_ByText ? filter_ByText : "",
      filter_levels,
      filterOrder
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_ByText, filter_levels, filterOrder]);

  const getBusinessCapabilityList = async (
    filter_By,
    filter_ByText,
    filter_levels,
    filterOrder
  ) => {
    setLoading(true);
    if (ref.current) {
      ref.current.continuousStart();
    }
    try {
      const list = await businessCapabilityServices.getList(
        filter_By,
        filter_ByText,
        filter_levels,
        filterOrder
      );
      const resultData = list?.data?.data;
      setLists(resultData);
      const dlist = resultData
        ?.filter((item) => item.parentid === 0)
        ?.map((item) => `${item?.id}`);
      setDefaultAcitveKey(dlist);
      setLoading(false);
      if (ref.current) {
        ref.current.complete();
      }
    } catch (error) {
      setLoading(false);
      if (ref.current) {
        ref.current.complete();
      }
    }
  };

  const setItem = (item, type) => {
    if (type.type === "edit") {
    } else {
      showConfirm(item);
    }
  };

  const showConfirm = (item) => {
    let id = item?.id;
    Modal.confirm({
      title: "Are you sure want to delete?",
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          let result;
          result = await businessCapabilityServices.deleteBusinessCapability(
            id
          );
          if (result) {
            let obj = {
              id: localStorage.getItem("id"),
              NameInSourceSystem: "",
              DisplayName: localStorage.getItem("DisplayName"),
              Description: "",
            };

            let field_OldData = {
              DisplayName: item?.DisplayName || "",
              Description: item?.Description || "",
            };

            await UpdateChangeLog(
              obj.id,
              field_OldData,
              obj,
              "Delete",
              stateMenu?.MenuID?.moduleName
            );

            await getBusinessCapabilityList();
            message.success(result?.data?.msg);
            // setId(null)
          } else {
            message.error(result?.data?.msg);
          }
        } catch (error) {
          message.error(error?.message);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const create_Duplicate = async (obj) => {
    try {
      const result = await businessCapabilityServices.create(obj);
      if (result.data?.code == "200") {
        getBusinessCapabilityList();
        setBcm2Index(null);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.code) {
        message.error(error?.response?.data?.msg);
      }
    }
  };

  return (
    <React.Fragment>
      <LoadingBar color="#1f1f1f" ref={ref} />
      <div className="d-flex justify-content-between">
        {/* <div className='w-100 towerview_bx' style={baseStyleRow} > */}
        <div className="w-100 towerview_bx container-fluid">
          <BusinessProcessHeader
            activePage={props.activeGridStyle}
            setActivePage={props.setActiveGridStyle}
            heading={"Business Capability"}
            addBtnTooltip={`Level 1`}
            lists={lists}
          />
          <div className="text-left business-capability-master">
            <div style={{ margin: 24 }} className="slider-container">
              <Carousel
                {...settings}
                style={{ width: window?.innerWidth - 300 }}
              >
                {lists?.map((item, index) => {
                  let color =
                    BCMSamples?.BCM_COLORS[
                      parseInt(index % BCMSamples?.BCM_COLORS.length)
                    ];
                  return (
                    <div key={index} className="align-items-start text-center">
                      <BPMLevel2
                        list={tempList}
                        draggingItem={draggingItem}
                        defaultAtiveKey={defaultAtiveKey}
                        setDraggingItem={setDraggingItem}
                        index={index}
                        backgroundColor={color}
                        setItem={setItem}
                        openModal={null}
                        visibleModal={props.visibleModal}
                        setModalData={() => {}}
                        data={item}
                        basePath={basePath}
                        create_Duplicate={create_Duplicate}
                        bcm2Index={bcm2Index}
                        setBcm2Index={setBcm2Index}
                        activeKey={activeKey}
                        setAcitveKey={setAcitveKey}
                        dragStart={dragStart}
                        setDragStart={setDragStart}
                        dragend={dragend}
                        setDragEnd={setDragEnd}
                        completeEnd={completeEnd}
                        setCompleteEnd={setCompleteEnd}
                        createNewArrey={createNewArrey}
                        setDragLevel={setDragLevel}
                        setCurrentLevel={setCurrentLevel}
                        loading={loading}
                        setLoading={setLoading}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BusinessCapabilityGrid;
